import './Select.scss';

const Select = ({data, onChange, currentStage}) => {
    return (
        <div id="select">
            { data.options.map((el, i) => <div onClick={() => onChange(el)} className={"option" + (currentStage.answer === el ? " selected" : "")} key={i}>{el}</div>) }
        </div>
    )
};

export default Select;