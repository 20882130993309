import './Interview.scss';
import Message from './components/Message/Message';
import TypingIndicator from './components/TypingIndicator/TypingIndicator';
import Select from './components/Select/Select';
import MultiSelect from './components/MultiSelect/MultiSelect';
import Delayed from './components/Delayed/Delayed';
import Input from './components/Input/Input';

import { useEffect, useState } from 'react';
import { from, of } from 'rxjs';
import { concatMap, delay } from 'rxjs/operators';
import { loadInterview } from '../../services/api';
import { getMessageContent } from './utils';
import { AiTwotoneStar } from 'react-icons/ai';
import { FaChevronLeft } from 'react-icons/fa';

let interviewData;
let maxStageIndex = 0;

const Interview = () => {
    const [currentStage, setCurrentStage] = useState();
    const [currentStageIdx, setCurrentStageIdx] = useState();
    const [viewedStages, setViewedStages] = useState([]);
    const [messages, setMessages] = useState([]);
    const [typingIndicator, setTypingIndicator] = useState(true);
    const actionsComponent = {
        'select': data => <Select data={data} onChange={stageActionHandler} currentStage={currentStage}/>,
        'multiselect': data => <MultiSelect data={data} onChange={stageActionHandler} currentStage={currentStage}/>,
        'input': data => <Input data={data} onChange={stageActionHandler} currentStage={currentStage}/>
    };

    useEffect(() => {
        loadInterview().then(res => {
            interviewData = res;
            setCurrentStageIdx(0);
        }).catch(err => console.log(err));
    }, []);

    useEffect(() => {
        setMessages([]);
        
        if (currentStageIdx > maxStageIndex) {
            maxStageIndex = currentStageIdx;
        }

        const dataRef = [];
        const stage = interviewData?.stages[currentStageIdx];
        if (!stage) return;

        if (!viewedStages.includes(currentStageIdx)) {
            setTypingIndicator(true);
    
            from(stage.messages).pipe(
                concatMap(val => of(val).pipe(delay(val.delay)))
            ).subscribe(el => {
                dataRef.push(formatMessage(el));
                setTypingIndicator(dataRef.length < stage.messages.length);
                setMessages([
                    ...dataRef
                ]);
                setViewedStages([
                    ...viewedStages,
                    currentStageIdx
                ]);
            }, null, () => {
                setCurrentStage(stage);
            });
        } else {
            setCurrentStage(stage);
            setMessages(stage.messages.map(el => formatMessage(el)));
        }
    }, [currentStageIdx]);

    const stageActionHandler = result => {
        interviewData.stages[currentStageIdx].answer = result;
        if (!hasMoreStages()) {
            window.location.href = "/done";
            return;
        }

        setCurrentStageIdx(currentStageIdx + 1);
    };
    const hasMoreStages = () => currentStageIdx < interviewData.stages.length - 1;
    const backClickHandler = () => {
        if (currentStageIdx > 0) {
            setCurrentStageIdx(currentStageIdx - 1);
            return;
        }

        window.location.href = '/';
    };
    const formatMessage = message => getMessageContent(message, interviewData);

    return (
        <div id="wrapper">
            <div id="chat-box">
                <div id="header">
                    <span onClick={backClickHandler} className="back"><FaChevronLeft/> <span>Back</span></span>
                    <div className="image"></div>
                    <div id="profile">
                        <p className="name">Sarah</p>
                        <p className="role">Senior Connectorz Advisor</p>
                        <span className="stars">
                            <AiTwotoneStar/>
                            <AiTwotoneStar/>
                            <AiTwotoneStar/>
                            <AiTwotoneStar/>
                            <AiTwotoneStar/>
                        </span>
                    </div>
                </div>

                <div id="messages-wrapper">
                    { messages.map((el, i) => <Message text={el} key={i} />) }
                    { typingIndicator && <TypingIndicator/> }
                </div>

                <div id="action-wrapper">
                    { !typingIndicator && currentStage && <Delayed waitBeforeShow={500}>{actionsComponent[currentStage.actions.type](currentStage.actions)}</Delayed> }
                </div>
            </div>
        </div>
    )
};

export default Interview;