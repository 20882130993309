import './MultiSelect.scss';
import { useEffect, useState } from 'react';
import { MdDone, MdAdd } from 'react-icons/md';

const MultiSelect = ({data, onChange, currentStage}) => {
    const [selected, setSelected] = useState([]);

    const optionClickHandler = (option) => {
        if (selected.includes(option)) {
            setSelected(selected.filter(el => el !== option));
        } else {
            if (selected.length >= data.required) {
                return;
            }

            setSelected([
                ...selected,
                option
            ]);
        }
    };

    useEffect(() => {
        if (currentStage?.answer) {
            setSelected(currentStage.answer);
        }
    }, []);

    return (
        <div id="multi-select">
            { data.options.map((el, i) => <div className={"option" + (selected.includes(el) ? ' selected' : '')} key={i} onClick={() => optionClickHandler(el)}>
                <span className="icon">{selected.includes(el) ? <MdDone/> : <MdAdd/>}</span>
                {el}
            </div>)}
            <div id="search">
                <input placeholder={data.placeholder} type="text"/>
                <button onClick={() => onChange(selected)} disabled={!selected.length}>Next</button>
            </div>
        </div>
    )
};

export default MultiSelect;