import './Navbar.scss';
import Logo from '../../assets/images/logo.svg';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import { SCREEN_MODES } from '../../utils/consts';

const Navbar = () => {
    const { width } = useWindowSize();
    const history = useHistory();
    const [menuOpen, setMenuOpen] = useState(false);
    const [hideLinks, setHideLinks] = useState(false);
    const [mode, setMode] = useState();

    useEffect(() => {
        setMode(width < 1300 ? SCREEN_MODES.MOBILE : SCREEN_MODES.DESKTOP);
    }, [width]);

    useEffect(() => {
        setHideLinks(window.location.pathname === '/interview');
    }, [history]);

    return (
        <div id="navbar" className={mode === SCREEN_MODES.MOBILE && hideLinks ? 'interview' : ''}>
            <button id="hamburger" className={"hamburger hamburger--squeeze " + (menuOpen && 'is-active')} onClick={() => setMenuOpen(!menuOpen)} type="button">
                <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                </span>
            </button>
            <div id="logo">
                <img src={Logo} alt="logo" />
            </div>
            {
                !hideLinks && <Links/>
            }
        </div>
    );
};

const Links = () => {
    return (
        <div id="links">
            <a href="/">Home</a>
            <a href="/">About</a>
            <a href="/">Contact</a>
        </div>
    )
};

export default Navbar;