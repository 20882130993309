const getStageAnswer = (idx, interviewData) => interviewData?.stages[idx].answer;
const getMessageContent = (el, interviewData) => {
    let formattedMessage = el.content;

    if (/(##\d+##)+/.test(formattedMessage)) {
        const splitted = el.content.split('##').filter(el => !!el);
        for (let i in splitted) {
            const el = +splitted[i];
            if (!Number.isInteger(el)) continue;
            
            splitted[i] = getStageAnswer(el, interviewData);
        }

        formattedMessage = splitted.join('');
    }

    return formattedMessage;
};

export { getMessageContent };