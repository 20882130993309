import './Signup.scss';
import Image4 from '../../assets/images/image4/profile 2.png'
import LinkedinLogo from '../../assets/images/linkedin-64.png';
import Footer from '../footer/Footer';
import useWindowSize from '../../hooks/useWindowSize';
import { SCREEN_MODES } from '../../utils/consts';
import { Fragment, useEffect, useState } from 'react';

const linkedinProfileUrlRegex = /^((http|https):\/\/)?(www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
const Signup = () => {
    const { width } = useWindowSize();
    const [mode, setMode] = useState();

    const [url, setUrl] = useState('');
    const [validUrl, setValidUrl] = useState();

    useEffect(() => {
        setValidUrl(linkedinProfileUrlRegex.test(url));
    }, [url]);

    useEffect(() => {
        setMode(width < 1300 ? SCREEN_MODES.MOBILE : SCREEN_MODES.DESKTOP);
    }, [width]);

    const startInterview = () => {
        window.location.href = "/interview";
    }

    return (
        <Fragment>
            <div id="signup">
                <div className="side left">
                    <p>Lets Sign You Up!</p>
                    <img src={Image4} alt="image4"/>
                </div>
                <div className="side right">
                    { mode === SCREEN_MODES.MOBILE && <LinkedInButton startInterview={startInterview}/> }
                    <p>You already built your professional profile and network on LinkedIn, let's put it to good use.</p>
                    { mode === SCREEN_MODES.DESKTOP && <LinkedInButton startInterview={startInterview}/> }
                    <p id="or">Or</p>

                    <p id="label">Enter your LinkedIn profile address below</p>

                    <input type="url" placeholder="https://www.linkedin.com/in/yourprofile" value={url} onChange={(e) => setUrl(e.target.value)}></input>

                    <small id="need-help">Need help finding your linkedIn profile address</small>

                    <button disabled={!validUrl} onClick={startInterview} id="next-button">Next</button>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
};

const LinkedInButton = ({startInterview}) => {
    return (
        <div id="linkedin-button" onClick={startInterview}>
            <img id="linkedin-logo" src={LinkedinLogo} alt="linkedin-logo" />
            <div className="vertical-border"></div>
            <p>Connect with LinkedIn</p>
        </div>
    )
};

export default Signup;