const interview = {
    stages: [
        {
            messages: [
                {
                    content: "Hey Zohar, welcome to Connectorz 🎉 I’m Sarah and I’m excited to see you have joined!",
                    delay: 500
                },
                {
                    content: "Thank you for linking your LinkedIn profile. While we are processing your account, I have a few quick questions:",
                    delay: 2000
                },
                {
                    content: "Which of the following roles best describe your line of work?",
                    delay: 1000
                }
            ],
            actions: {
                type: "select", // select | multiselect | input
                options: [
                    "Technology",
                    "Product",
                    "Marketing",
                    "Sales",
                    "Operations",
                    "Finance"
                ]
            }
        },
        {
            messages: [
                {
                    content: "##0## is exciting!",
                    delay: 1000
                },
                {
                    content: "How many years of ##0## experience do you have?",
                    delay: 1500
                }
            ],
            actions: {
                type: "select", // select | multiselect | input
                options: [
                    "0-1 years",
                    "1-2 years",
                    "2-4 years",
                    "4-6 years",
                    "6-10 years",
                    "10+ years"
                ]
            }
        },
        {
            messages: [
                {
                    content: "That’s impressive! What full time roles have you held lately?",
                    delay: 800
                },
                {
                    content: "You can choose up to 3 but “less is more” in this case :-)",
                    delay: 1000
                }
            ],
            actions: {
                type: "multiselect", // select | multiselect | input
                required: 3,
                placeholder: "Search for roles",
                options: [
                    "Product Innovation",
                    "Head of Product",
                    "UI/UX Design",
                    "Data Scientist",
                    "Growth Marketer",
                    "Brand Manager",
                    "AI/ML",
                    "Project Manager",
                    "Business Development",
                    "Game Design"
                ]
            }
        },
        {
            messages: [
                {
                    content: "There is lot of demand in your space!",
                    delay: 500
                },
                {
                    content: "What offers will make you go Hmmm💡? Think total annual compensation",
                    delay: 1000
                }
            ],
            actions: {
                type: 'input',
                renderer: {
                    type: 'currency'
                }
            }
        }
    ]
};

export const loadInterview = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            return resolve(interview);
        }, 2000);
    });
};