import './Landing.scss';
import { Fragment, useEffect, useState } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { SCREEN_MODES } from '../../utils/consts';
import Footer from '../footer/Footer';

import Illustration from '../../assets/images/Illustration.svg';
import Image1 from '../../assets/images/image1/Image.png';
import Image2 from '../../assets/images/image2/Image.png';
import Image3 from '../../assets/images/image3/Image.png';
import PhoneIllustration from '../../assets/images/Phone Illustration.svg';
import Icon6 from '../../assets/logos/Icon 6.svg';
import Icon7 from '../../assets/logos/icon 7.svg';
import Icon8 from '../../assets/logos/icon 8.svg';
import Icon9 from '../../assets/logos/icon 9.svg';
import Icon10 from '../../assets/logos/icon 10.svg';
import Icon11 from '../../assets/logos/icon 11.svg';

const Landing = () => {
    const { width } = useWindowSize();
    const [mode, setMode] = useState();

    useEffect(() => {
        setMode(width < 1300 ? SCREEN_MODES.MOBILE : SCREEN_MODES.DESKTOP);
    }, [width]);

    return (
        <Fragment>
            <div className="card padding">
                <div className="text">
                    <div className="title">
                        <p>Make an into.</p>
                        <p>Make a difference.</p>
                        <p>Make money.</p>
                    </div>
                    {
                        mode === SCREEN_MODES.DESKTOP &&
                        <p className="description regular-font">A company you know wants to hire someone you know. Make an intro. <strong className="bold">Get paid!</strong></p>
                    }
                    <SignupButton label="Sign me up!"/> 
                    {
                        mode === SCREEN_MODES.MOBILE &&
                        <p className="description regular-font mobile">A company you know<br/> wants to hire someone you know.<br/> Make an intro. <strong className="bold">Get paid!</strong></p>
                    }
                </div>
                <div id="illustration">
                    <img src={Illustration} alt="illustration"/>
                </div>
            </div>

            <div className="card padding">
                <div className="img">
                    <img src={Image1} alt="image1"/>
                </div>
                <div className="text">
                    <p className="title">We all love a good referral program!</p>
                    <p className="description regular-font">
                        Your company is looking for talent.<br/>
                        Your friend is very talented.<br/>
                        Friend geta a new job.<br/>
                        You get a refferal bonus. <strong className="bold">Cha-Ching!</strong>
                    </p>
                </div>
            </div>

            <div className="card padding">
                <div className="text">
                    <p className="title">It’s Time For Your Social Networks To Work For You!</p>
                    <p className="description regular-font">
                        You have the potential to make over 500,000 introductions. We will find you the ones that can <span className="primary-color">make a difference</span> and earn you money!
                    </p>
                </div>
                <div className="img">
                    <img src={Image2} alt="image2"/>
                </div>
            </div>

            <div className="card padding">
                { mode === SCREEN_MODES.MOBILE && <SignupButton label="Sign me up!"/> }
                <div className="img">
                    <img src={Image3} alt="image3"/>
                </div>
                <div className="text">
                    <p className="title">It’s Ridiculous<br/> How Simple It Is!</p>
                    <p className="description regular-font">
                        Companies spend billions looking for hires and we found a way for you to get a nice piece of it - right from your phone.!
                    </p>
                    { mode === SCREEN_MODES.DESKTOP && <SignupButton label="Sign me up!"/> }
                </div>
            </div>

            <div id="are-you-ready" className="card">
                <div className="text">
                    <p className="title">Are you ready?</p>
                    <p className="question question1"><strong>10 questions</strong><br/>To get to know you</p>
                    <p className="question question2"><strong>500,000 ways</strong><br/>To get paid</p>
                    { mode === SCREEN_MODES.DESKTOP && <SignupButton label="Yes, i'm ready!"/> }
                </div>
                <div className="img">
                    <img src={PhoneIllustration} alt="phone"/>
                </div>
                { mode === SCREEN_MODES.MOBILE && <SignupButton label="Yes, i'm ready!"/> }
            </div>

            <div className="how-it-works">
                <div className="header">
                    <p className="title">How Connectorz Works</p>
                    <p className="description">Companies pay recruiters Billions of Dollars to help them find new hires. Connectorz disrupts this traditional model by facilitating effective introductions between hiring companies and the talent they look for - the people you know. Same Billions, different pockets.<br/>We get a fee, <span className="bold">you get a referral bonus.</span></p>
                </div>
                <div className="grid">
                    <div className="item">
                        <div className="logo">
                            <img src={Icon7} alt="logo1"/>
                        </div>
                        <div className="description">A company you worked with right after school is looking to hire a very specific skill set </div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={Icon8} alt="logo1"/>
                        </div>
                        <div className="description">We know that Shila has this specific skill set</div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={Icon9} alt="logo1"/>
                        </div>
                        <div className="description">Shila is your Facebook friend. You went to school with Shila</div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={Icon10} alt="logo1"/>
                        </div>
                        <div className="description">Shila gets a new gig</div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={Icon6} alt="logo1"/>
                        </div>
                        <div className="description">You get a referral bonus</div>
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={Icon11} alt="logo1"/>
                        </div>
                        <div className="description"></div>
                    </div>
                </div>
                { mode === SCREEN_MODES.MOBILE && <SignupButton label="Let's do it!"/> }
            </div>

            <Footer />
        </Fragment>
    );
};

const SignupButton = ({label}) => <button className="sign-up-button" onClick={() => navigate("/signup")}><a href="/signup">{label}</a></button>;
const navigate = url => window.location.href = url;

export default Landing;