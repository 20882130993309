import WhiteLogo from '../../assets/images/logo-white.svg';
import './Footer.scss';

const Footer = () => {
    return (
        <div id="footer">
            <img src={WhiteLogo} alt="white-logo"/>
            <div className="links">
                <a href="/" className="link">Blog</a>
                <a href="/" className="link">FAQ</a>
                <a href="/" className="link">Community</a>
                <a href="/" className="link">Our Mission</a>
                <a href="/" className="link">Where We're Live</a>
                <a href="/" className="link">Investor Relations</a>
            </div>
        </div>
    )
};

export default Footer;