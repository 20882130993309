import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navbar from './pages/navbar/Navbar';
import Landing from './pages/landing/Landing';
import Signup from './pages/signup/Signup';
import Interview from './pages/interview/Interview';
import Done from './pages/done/Done';

function App() {
	return (
		<Router>
			<div id="container">
				<Navbar></Navbar>
				<div id="router-outlet">
					<Switch>
						<Route path="/done">
							<Done />
						</Route>
						<Route path="/signup">
							<Signup/>
						</Route>
						<Route path="/interview">
							<Interview/>
						</Route>
						<Route path="/">
							<Landing/>
						</Route>
					</Switch>
				</div>
			</div>
		</Router>
  	);
}

export default App;
