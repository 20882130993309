import './Input.scss';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { useState } from 'react';

const salaryMask = createNumberMask({
    prefix: '$',
    suffix: ''
});

const Input = ({data, onChange, currentStage}) => {
    const [value, setValue] = useState();
    const renderers = {
        'currency': () => <Currency value={value} setValue={setValue}/>
    };
    
    if (currentStage.answer) {
        setValue(currentStage.answer);
    }

    return (
        <div id="input">
            { data.renderer && renderers[data.renderer.type]() }

            <button disabled={!value} onClick={() => onChange(value)} id="next-button">Next</button>
        </div>
    )
};

const Currency = ({value, setValue}) => {
    return (
        <MaskedInput
            id='currency'
            mask={salaryMask}
            placeholder="$120,000"
            guide={false}
            value={value}
            autoComplete='off'
            type="tel"
            onChange={e => setValue(e.target.value)}
        />
    );
};

export default Input;