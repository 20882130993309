import './TypingIndicator.scss';

const TypingIndicator = () => {
    return (
        <div id="ticontainer">
            <div className="tiblock">
                <div className="tidot"></div>
                <div className="tidot"></div>
                <div className="tidot"></div>
            </div>
        </div>
    )
};

export default TypingIndicator;