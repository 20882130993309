import './Done.scss';
import { Fragment, useState } from "react";
import Footer from "../footer/Footer";
import useWindowSize from '../../hooks/useWindowSize'
import Confetti from 'react-confetti'
import doneImage from '../../assets/images/done/done@3x.png'

const Done = () => {
    const { width, height } = useWindowSize()
    const [email, setEmail]  = useState()
    const [validEmail, setValidEmail] = useState(false)

    const buttonClickHandler = () => {
        window.location.href = '/';
    };
    const inputChangeHandler = (email) => {
        setEmail(email);
        setValidEmail(validateEmail(email));
    };

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    return (
        <Fragment>
            <Confetti
                width={width}
                height={height}
            />
            <div id="done-wrapper">
                <div id="left">
                    <p>The results are in...</p>
                    <img src={doneImage} alt='done'></img>
                </div>
                <div id="right">
                    <p id="title">You are awesome!</p>
                    <p id="description">What is the best email we should use to send you new refferal opprotunities?</p>
                    
                    <input type="email" placeholder="Write your email" value={email} onChange={(e) => inputChangeHandler(e.target.value)}></input>
                    <button disabled={!validEmail} onClick={buttonClickHandler} id="awesome-button">I am awesome!</button>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default Done;